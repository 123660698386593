<template>
	<div class="block">	
	  <h2>目前共计{{ types.length }}个分类</h2>
	  <el-timeline>
	    <el-timeline-item placement="top" v-for="(type,index) in types" :key='index'>
	      <el-card>
	        <h2 v-text="type.type"></h2>
	        <el-row class="typed-all">
	          <el-col :span="8" v-for="(article, index) in type.articles" :key="index">
	            <el-card :body-style="{ padding: '0px' }">
	              <div style="-webkit-clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%);clip-path: polygon(0px 0px, 100% 0px, 85% 100%, 0% 100%)" v-show="index%2==0" class="div-type-img"><a :href="/article/+article.aid"><img v-lazy="article.topImg" alt="topImg" class="image"></a></div>
	              <div style="padding: 14px;">
					<div class="msg" :class="index%2!=0?'even':''">
						  <span title="创建时间"><span><i class="el-icon-date"></i></span><time class="time" v-text="$moment(article.createTime).format('YYYY-MM-DD')"></time></span>
						  <span title="浏览量"><span><i class="el-icon-view"></i></span><span v-text="article.views"></span></span>
						  <span title="评论数"><span><i class="el-icon-chat-dot-round"></i></span><span v-text="article.countsComment"></span></span>
					</div>
	                <h3 ><a :href="/article/+article.aid" v-text="article.title"></a></h3>
	                <div class="bottom clearfix" v-text="article.description">
	                </div>
	              </div>
				  <div style="-webkit-clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);" v-show="index%2!=0" class="div-type-img"><a :href="/article/+article.aid"><img v-lazy="article.topImg" alt="topImg" class="image"></a></div>
	            </el-card>
	          </el-col>
	        </el-row>
	      </el-card>
	    </el-timeline-item>
	  </el-timeline>
	</div>
</template>

<script>
	export default {
		name: 'TypeC',
		data() {
			return {
				types: []
			}
		},
		methods: {
			getTypes(){
				let that = this
				this.axios.post('types',{}).then(function(results){
					that.types = results.data
				},function(err){
					console.log(err)
				})
			}
		},
		created() {
			this.getTypes()
		}
	}
</script>

<style>
	.el-timeline{
		width: 50%;
		margin: 0 auto;
	}
  .block h2{
	color: white;
  }
  .time {
    font-size: 13px;
    color: var(--theme-color);
  }
  
  .el-card .bottom {
	color: var(--theme-color);
    margin-top: 1.5em;
    line-height: 1.5em;
	text-align: left;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
  }

  .button {
    padding: 0;
    float: right;
  }
  .el-card{
	  background-color: unset;
  }
  .typed-all>.el-col{
	  transition: all .3s ease-in-out;
  }
  .typed-all>.el-col>.el-card{
	  border: unset;
	  background-color: var(--theme-background-color);
  }

  .block .image {
    width: 100%;
	height: 240px;
    display: block;
	transition: all .3s ease-in-out;
  }
  
  .block .el-col .el-card .el-card__body:hover .image{
	  transform: scale(1.2);
  }
  
  .typed-all>.el-col:hover{
	  box-shadow:0 2px 12px 0 rgb(0 0 0 / 50%);
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
  .el-row{
	display: flex;
	flex: auto;
	flex-wrap: wrap
  }
  .el-col-8{
	  width: 100%;
	  margin: 1em;
  }
  .el-card__body h2{
	  position: relative;
	  text-align: start;
	  color: #29BDFF;
	  margin-left: 1em;
	  text-indent: .5em;
	  padding-top: 0;
	  transition: all .2s ease-in-out;
  }
  .el-card__body h2::before{
	  content: '';
	  position: absolute;
	  left: -1em;
	  top: .3em;
	  width: .6em;
	  height: .6em;
	  border: 3px solid #29BDFF;
	  border-radius: 50%;
	  transition: all .2s ease-in-out;
  }
  .el-card__body h2:hover::before{
	  border: 3px solid pink;
  }
  .el-card__body h2:hover{
	  color: pink;
  }
  .block .el-col .el-card .el-card__body{
	padding: 0px;
	display: flex;
	flex: auto;
	flex-wrap: nowrap;
  }
  .block .el-col .el-card .el-card__body>div{
	  flex: auto;
	  width: 50%;
  }
  .el-col .el-card .el-card__body>div:nth-child(odd){
	  overflow: hidden;
  }
  .el-col .el-card .el-card__body div:nth-last-child(2){
	  text-align: center;
  }
  .el-col .el-card .el-card__body h3{
	  margin-top: 2em;
  }
  .el-col .el-card .el-card__body h3 a{
	  color: var(--theme-color);
  }
  .msg{
	  text-align: end;
  }
  .msg span,.msg time{
	  color: var(--theme-color);
	  margin-right: .3em;
  }
  .even{
	  text-align: start;
  }
  /* .darkApp .typed-all>.el-col>.el-card{
	  background-color: #171212;
  } */
  @media only screen and (max-width: 770px){
	  .el-timeline{
		  width: 98%;
	  }
	  .el-card__body{
		  padding: 2px;
	  }
	  .el-col .el-card .el-card__body h3{
		  white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
	  }
	  .el-timeline-item__wrapper{
		  padding-left: 1em;
	  }
	  .el-col .el-card .el-card__body>div{
		  height: 130px;
	  }
	  .el-col .el-card .el-card__body h3{
		  margin-top: 1em;
	  }
	  .bottom{
		  margin-top: 1em;
	  }
	  .image{
		  
	  }
	  .div-type-img{
		  display: none;
	  }
	  .msg{
		  text-align: start;
	  }
  }
</style>
