<template>
	<div class="type">
		<Nav></Nav>
		<TopCover :pageMsg='pageMsg'></TopCover>	
		<TypeC></TypeC>	
	</div>
</template>

<script>
import TypeC from '@/components/Type.vue'
import TopCover from '@/components/TopCover.vue'
import Nav from '@/components/Nav.vue'

export default {
  metaInfo: {
      title: '分类 - KASUIEの次元',
      meta: [
  	    {
  		    name: 'description',
              content: '文章分类',
  	    },
          {
              name: 'keywords',
              content: '文章分类，博客文章，分类'
          }
    ]
  },
  name: 'Type',
  data() {
  	return {
		pageMsg:{
			title: '分类',
			imgUrl: 'https://i.loli.net/2021/07/15/DJoQzfl47SUjBCN.jpg',
			article: ''
		},
	}
  },
  components: {
    TypeC,
	TopCover,
	Nav
  },
  created() {
  	document.title = 'KASUIEの分类'
	let title
	setTimeout(function() {
	   	title = document.title
	}, 1000);
	window.onfocus = function () {
		document.title = '恢复正常了...';
	    setTimeout(function() {
			document.title = title
		}, 1000);
	};
	window.onblur = function () {
		setTimeout(function() {
			document.title = '快回来~页面崩溃了'
		}, 5000);
	};
  },
  mounted() {
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style>
.type{
	position: relative;
}
</style>
